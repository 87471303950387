import { getProfileRoutes } from "@/router/profileRoutes";
import { getSupportRoutes } from "@/router/supportRoutes";
import { clearSelectedPatientStoreData } from "@/store";
import { THERAPIST_ROLE, SITEMANAGER_ROLE } from "@/services/auth.js";
import oAuthRoutes from "@/router/oAuthRoutes";

const routePrefix = "/therapist";

export default [
  // Default therapist layout
  {
    path: "/",
    component: () => import("@/pages/TherapistPages/TherapistHomePage"),
    meta: {
      requiresTherapist: true,
    },
    children: [
      ...getSupportRoutes(routePrefix),
      ...getProfileRoutes(routePrefix),
      {
        path: "/patient-list",
        name: "Therapist/Home",
        component: () =>
          import("@/pages/TherapistPages/TherapistPatientListPage"),
        beforeEnter: (to, from, next) => {
          // Clears potentially previous patient data present from the stores
          clearSelectedPatientStoreData();
          next();
        },
        props: (route) => ({
          showSuccessModal: route.params.showSuccessModal,
          patientSiteId: route.params.patientSiteId,
          isCreatedUserPasswordSet: route.params.isCreatedUserPasswordSet,
        }),
      },
      {
        path: "/patient/:username",
        name: "Therapist/Patient",
        redirect: "/patient/:username/plan",
        component: () =>
          import("@/pages/TherapistPages/TherapistPatientManagementPages"),
        meta: {
          requiresTherapist: true,
        },
        props: (route) => ({
          patientUsername: route.params.username,
        }),
        children: [
          {
            path: "dashboard",
            name: "Therapist/Patient/Dashboard",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/DashboardPage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
              openAttGoalModal: route.params.openAttGoalModal,
            }),
          },
          {
            path: "plan",
            name: "Therapist/Patient/Plan",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/TherapyPlanPage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
            }),
          },
          {
            path: "teleconsultation",
            name: "Therapist/Patient/Teleconsultation",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/TeleRehabPage/PatientTeleRehab"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
            }),
          },
          {
            path: "monitor",
            name: "Therapist/Patient/Monitor",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/MonitorPage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
            }),
          },
          {
            path: "review",
            name: "Therapist/Patient/Review",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/ReviewPage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
              expandSessionList: route.params.expandSessionList,
            }),
          },
          {
            path: "assessment",
            name: "Therapist/Patient/Assessment",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/AssessmentPage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
            }),
          },
          {
            path: "epro",
            name: "Therapist/Patient/EPRO",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/EPROPage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
            }),
          },
          {
            path: "profile",
            name: "Therapist/Patient/Profile",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistPatientManagementPages/ProfilePage"
              ),
            props: (route) => ({
              currentUsername: route.params.username,
            }),
          },
        ],
      },
      {
        path: "/new-patient",
        name: "Therapist/AddPatient",
        component: () =>
          import("@/pages/TherapistPages/TherapistAddPatientPage"),
        meta: {
          requiresTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/assigned/:siteUnitId/patients/new",
        component: () =>
          import("@/pages/TherapistPages/TherapistAddPatientPage"),
        props: (route) => ({
          siteUnitId: route.params.siteUnitId,
        }),
        meta: {
          requiresTherapist: true,
        },
      },
      {
        path: "/epro-patients",
        name: "Therapist/EPROPatientList",
        component: () => import("@/pages/TherapistPages/EPROPatientListPage"),
        meta: {
          requiresTherapist: true,
        },
      },
      {
        path: "/meetings",
        name: "Therapist/Meetings",
        component: () =>
          import("@/pages/TherapistPages/TherapistTeleRehabPage"),
        meta: {
          requiresTherapist: true,
        },
        beforeEnter: (to, from, next) => {
          // Clears potentially previous patient data present from the stores
          clearSelectedPatientStoreData();
          next();
        },
      },
      {
        path: "training",
        name: "Therapist/TrainingMaterial",
        component: () =>
          import("@/pages/TherapistPages/TherapistTrainingMaterialPages"),
        meta: {
          requiresTherapist: true,
        },
        redirect: "/training/playlists",
        children: [
          {
            path: "playlists",
            name: "Therapist/TrainingMaterial/PlayLists",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistTrainingMaterialPages/TrainingMaterialPlayListPage"
              ),
            meta: {
              requiresTherapist: true,
            },
          },
          {
            path: "playlists/:playlistId/:rank",
            name: "Therapist/TrainingMaterial/PlayList",
            component: () =>
              import(
                "@/pages/TherapistPages/TherapistTrainingMaterialPages/TrainingMaterialViewerPage"
              ),
            meta: {
              requiresTherapist: true,
            },
            props: (route) => ({
              playlistId: route.params.playlistId,
              rank: route.params.rank,
            }),
          },
        ],
      },
      {
        path: "templates",
        name: "Therapist/Templates",
        component: () =>
          import("@/pages/TherapistPages/TherapistTemplatesPage"),
        meta: {
          requiresTherapist: true,
        },
      },

      // Therapist admin routes
      {
        path: "therapist-admin/therapists",
        name: "TherapistAdmin/Therapists",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/UserListPage"),
        props: {
          "user-type": "therapist",
        },
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/therapists/new",
        name: "TherapistAdmin/CreateTherapist",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/AddUser"),
        props: {
          "user-type": "therapist",
        },
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/therapists/:username",
        name: "TherapistAdmin/EditTherapist",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          username: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-managers",
        name: "TherapistAdmin/SiteManagers",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/UserListPage"),
        props: {
          "user-type": "site",
        },
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-managers/new",
        name: "TherapistAdmin/CreateSiteManager",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/AddUser"),
        props: {
          "user-type": "site",
        },
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-managers/:username",
        name: "TherapistAdmin/EditSiteManager",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          username: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/archived-users",
        name: "TherapistAdmin/ArchivedUsers",
        component: () =>
          import(
            "@/pages/SiteManagerPages/SiteManagerHomePage/ArchivedUsersPage.vue"
          ),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/archived-users/:username",
        name: "TherapistAdmin/EditArchivedUser",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          username: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/license",
        name: "TherapistAdmin/License",
        component: () =>
          import(
            "@/pages/SiteManagerPages/SiteManagerHomePage/LicenseOverview"
          ),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-parameters",
        name: "TherapistAdmin/SiteManagement/SiteParameters",
        component: () => import("@/pages/SiteManagerPages/SiteParameters"),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/patient-training-data",
        name: "TherapistAdmin/ExportTrainingData",
        component: () =>
          import("@/pages/TherapistPages/AdminPages/PatientTrainingData"),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/personal-access-token",
        name: "TherapistAdmin/SiteManagement/PersonalAccessToken",
        component: () => import("@/pages/SiteManagerPages/PersonalAccessToken"),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/personal-access-token/new",
        name: "TherapistAdmin/SiteManagement/CreatePersonalAccessToken",
        component: () =>
          import(
            "@/pages/SiteManagerPages/PersonalAccessToken/AddPersonalAccessToken"
          ),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      // Site Units
      {
        path: "therapist-admin/site-units",
        component: () =>
          import(
            "@/pages/EntityManagementPages/SiteUnitManagementPages/SiteUnitListPage"
          ),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/new",
        component: () =>
          import("@/pages/SiteManagerPages/SiteUnit/AddSiteUnit"),
        props: (route) => ({ siteId: route.params.siteId }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/assigned/:siteUnitId/:tab",
        component: () =>
          import("@/pages/SiteManagerPages/SiteUnit/EditSiteUnit"),
        props: (route) => ({
          siteUnitId: route.params.siteUnitId,
          tab: route.params.tab,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/assigned/:siteUnitId/:tab/new",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/AddUser"),
        props: (route) => ({
          "user-type":
            route.params.tab === "therapists"
              ? THERAPIST_ROLE
              : SITEMANAGER_ROLE,
          siteUnitId: route.params.siteUnitId,
          tab: route.params.tab,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/assigned/:siteUnitId/patients/:username",
        component: () =>
          import(
            "@/pages/TherapistPages/TherapistPatientManagementPages/ProfilePage"
          ),
        props: (route) => ({
          siteUnitId: route.params.siteUnitId,
          currentUsername: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/assigned/:siteUnitId/:tab/:username",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          siteUnitId: route.params.siteUnitId,
          tab: route.params.tab,
          username: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      {
        path: "therapist-admin/site-units/unassigned/:tab",
        component: () =>
          import("@/pages/SiteManagerPages/SiteUnit/UnassignedUsersPage"),
        props: (route) => ({
          tab: route.params.tab,
          unassignedUserIds: route.params.unassignedUserIds,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      // Site managers, therapists
      {
        path: "therapist-admin/site-units/unassigned/:tab/:username",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          tab: route.params.tab,
          username: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
      // Patients
      {
        path: "therapist-admin/site-units/unassigned/:tab/:username/profile",
        component: () =>
          import(
            "@/pages/TherapistPages/TherapistPatientManagementPages/ProfilePage"
          ),
        props: (route) => ({
          currentUsername: route.params.username,
        }),
        meta: {
          requiresSuperTherapist: true,
        },
      },
    ],
  },
  // Teleconsultation meeting page
  {
    path: "/meetings/:meetingId",
    name: "Therapist/LiveMeeting",
    component: () => import("@/pages/TherapistPages/TherapistConferencePage"),
    meta: {
      requiresTherapist: true,
    },
    props: (route) => ({
      meetingId: route.params.meetingId,
    }),
  },
  // OAuth Zoom authentication
  ...oAuthRoutes,
];
